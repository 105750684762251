import loadable from '../../utilities/loadable'

export const BusinessInformation = loadable(() =>
  import('./ApplicationDetails/BusinessInformation/BusinessInformation')
)
export const CreditInformation = loadable(() =>
  import('./ApplicationDetails/CreditInformation/CreditInformation')
)
export const PaymentInformation = loadable(() =>
  import('./ApplicationDetails/PaymentInformation/PaymentInformation')
)
export const ReviewSign = loadable(() =>
  import('./ApplicationDetails/ReviewSign')
)
export const SelectWholesaler = loadable(() =>
  import('./ApplicationDetails/SelectWholesaler/SelectWholesaler')
)
