import loadable from '../../utilities/loadable'

export const DisableRequestButton = loadable(() =>
  import('./DisableRequestButton')
)
export const EnableRequestButton = loadable(() =>
  import('./EnableRequestButton')
)
export const NewRequestModal = loadable(() => import('./NewRequestModal'))
export const SubscribeButton = loadable(() => import('./SubscribeButton'))
export const UnsubscribeButton = loadable(() => import('./UnsubscribeButton'))
