import loadable from '../../utilities/loadable'

export const Invoices = loadable(() => import('./Invoices/Invoices'))
export const MyWholesalers = loadable(() =>
  import('./MyWholesalers/MyWholesalers')
)
export const Orders = loadable(() => import('./Orders/Orders'))
export const Pedigrees = loadable(() => import('./Pedigrees/Pedigrees'))
export const PurchasedItems = loadable(() =>
  import('./PurchasedItems/PurchasedItems')
)
export const Reports = loadable(() => import('./Reports'))
export const TopSellingProducts = loadable(() =>
  import('./TopSellingProducts/TopSellingProducts')
)
