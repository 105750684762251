import loadable from '../../utilities/loadable'

export const ReturnButtonRoot = loadable(() =>
  import('./RequestItemReturn/ReturnButtonRoot')
)
export const RequestReturnRoot = loadable(() =>
  import('./RequestOrderReturn/RequestReturnRoot')
)
export const ReturnRequestsRoot = loadable(() =>
  import('./ReturnRequests/ReturnRequestsRoot')
)
