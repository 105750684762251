import loadable from '../../utilities/loadable'

export const ApplyForControlsUploadModal = loadable(() =>
  import('./Modals/ApplyForControlsUploadModal')
)
export const RequestPaymentAccountChangeModal = loadable(() =>
  import('./Modals/RequestPaymentAccountChangeModal')
)
export const NoticeBarIcon = loadable(() => import('./NoticeBarIcon'))
export const WholesalerDetails = loadable(() => import('./WholesalerDetails'))
