import loadable from '../../utilities/loadable'

export const AddButtonRoot = loadable(() => import('./AddToCart/AddButtonRoot'))
export const QuantityInputRoot = loadable(() =>
  import('./AddToCart/QuantityInputRoot')
)
export const HeaderSummaryContainer = loadable(() =>
  import('./HeaderSummary/HeaderSummaryContainer')
)
export const ShoppingCartRoot = loadable(() => import('./ShoppingCartRoot'))
export const CheaperAlternativeModal = loadable(() =>
  import('./Tabs/ItemsTab/CheaperAlternativeModal')
)
export const MoveToCartButtonRoot = loadable(() =>
  import('./Tabs/SavedForLaterTab/MoveToCartButtonRoot')
)

export const InstantCheckoutDeliverySelect = loadable(() =>
  import('./Tabs/ItemsTab/InstantCheckout/InstantCheckoutDeliverySelect')
)

export const InstantCheckoutPaymentSelect = loadable(() =>
  import('./Tabs/ItemsTab/InstantCheckout/InstantCheckoutPaymentSelect')
)
