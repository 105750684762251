import loadable from '../../utilities/loadable'

export const SearchProducts = loadable(() =>
  import('./AddProductToOrder/SearchProducts')
)
export const OrderConfirmation = loadable(() =>
  import('./OrderConfirmation/OrderConfirmation')
)
export const AddInfoModal = loadable(() => import('./Rph2Rph/AddInfoModal'))
export const PickupRequest = loadable(() => import('./Rph2Rph/PickupRequest'))
export const ShippingDocuments = loadable(() =>
  import('./Rph2Rph/ShippingDocuments')
)
